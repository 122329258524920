import { MyOffersSingleOfferResponse } from '@thirsty-camel/hump-club/src/modules/offer/offer.object'
import { Stack, Card, Heading, IconButton, StackProps, Link, Grid, Button } from '@thirstycamel/ui'
import { useState } from 'react'

export const AvailableOffer = ({
  offer,
  isApplied,
  onSelect,
  onRemove,
  isLoading,
  redeemable = true,
  ...restProps
}: StackProps & {
  isLoading: boolean
  offer: MyOffersSingleOfferResponse
  isApplied?: boolean
  onSelect?: () => void
  onRemove?: () => void
  redeemable?: boolean
}) => {
  let viewLink = '/specials'

  if (offer.productBarcode) {
    viewLink = `/search?barcodes=${offer.productBarcode.join(',')}`
  }
  
  return (
    <Grid
      gridTemplateColumns="1fr 80px"
      as={Card}
      isInline
      spacing={4}
      borderColor={(redeemable && isApplied) ? "green.500" : "gray.200"}
      bg={(redeemable && isApplied) ? "green.100" : "gray.50"}
      py={3}
      px={4}
      flex="1 1 0"
      justify="space-between"
      flexShrink={0}
      {...restProps}
    >
      <Stack spacing={1} flexShrink={0}>
        <Heading fontSize="xs" textTransform="uppercase" color="pink.500">
          {offer.name}
        </Heading>

        <Heading fontSize="2xs">{offer.shortDescription}</Heading>
      </Stack>

      {!redeemable ? (
        <Link href={viewLink} hrefAs={viewLink}>
          <Button
            isRound
            size="sm"
            variant="outline"
            variantColor={'pink'}
            isLoading={isLoading}
          >
            View
          </Button>
        </Link>
      ) : isApplied ? (
        <Button
          isRound
          size="sm"
          variant="outline"
          variantColor={'pink'}
          isLoading={isLoading}
          onClick={() => onRemove()}
        >
          Remove
        </Button>
      ) : (
        <Button
          isRound
          size="sm"
          variantColor={'pink'}
          isLoading={isLoading}
          onClick={() => onSelect()}
        >
          Apply
        </Button>
      )}
    </Grid>
  )
}

export default AvailableOffer
