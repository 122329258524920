import { useRef, useState } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import './confetti.css'

import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  Card,
  Heading,
  Icon,
  IconButton,
  Divider,
  useToast,
  Box,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { MyOffersSingleOfferResponse } from '@thirsty-camel/hump-club/src/modules/offer/offer.object'
import AvailableOffer from '../AvailableOffer'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'

dayjs.extend(relativeTime)

const ModalApplyOffer: React.FC<
  ModalProps & { offers: MyOffersSingleOfferResponse[]; otherOffers: MyOffersSingleOfferResponse[] }
> = ({ isVisible, onClose, offers, otherOffers }) => {
  const toast = useToast()
  const { isFetching, cart } = useStore(store => store.cart)
  const { applyOffer, removeOffer } = useActions(actions => actions.cart)
  const initialFocusRef = useRef(null)

  const handleSelectOffer = async (offer: MyOffersSingleOfferResponse) => {
    try {
      await applyOffer(offer.offerId)
      toast({
        title: 'Offer added to order',
        status: 'success',
      })
    } catch (e) {
      toast({
        title: getNiceErrorMessage(e),
        status: 'error',
      })
    }
  }

  const handleRemoveOffer = async (offer: MyOffersSingleOfferResponse) => {
    try {
      await removeOffer(offer.offerId)
      toast({
        title: 'Offer removed from order',
        status: 'success',
      })
    } catch (e) {
      toast({
        title: getNiceErrorMessage(e),
        status: 'error',
      })
    }
  }

  const { width, height } = useWindowSize()

  return (
    <>
      {typeof window !== 'undefined' && offers?.find(offer => offer.isRecentWin) ? (
        <Confetti width={window.innerWidth} height={window.innerHeight * 2} />
      ) : null}
      <Modal
        onClose={onClose}
        isVisible={isVisible}
        initialFocusRef={initialFocusRef}
        heading="Offers"
      >
        <Stack py={2} spacing={5} flexShrink={0}>
          {offers.length ? (
            <Stack spacing={5} flexShrink={0}>
              <Text fontSize="sm">
                You have Hump Club offers that you can apply to this order. Please select an offer
                below to apply it to this order.
              </Text>
              <Divider />
              <Box flexShrink={0}>
                {offers.map(offer => (
                  <AvailableOffer
                    offer={offer}
                    isApplied={!!cart.applicableHumpClubOffers?.find(ao => ao?.offerId === offer?.offerId)}
                    redeemable
                    isLoading={isFetching}
                    onSelect={() => {
                      handleSelectOffer(offer)
                    }}
                    onRemove={() => {
                      handleRemoveOffer(offer)
                    }}
                    mb={2}
                  />
                ))}
              </Box>
              {otherOffers.length && (
                <Stack spacing={5}>
                  <Divider />
                  <Heading>Other offers</Heading>
                  <Text fontSize="sm">
                    You have the following other offers available. They're not applicable to this
                    cart but are available to you!
                  </Text>
                </Stack>
              )}
            </Stack>
          ) : (
            <Text>
              You have no offers that apply to this cart but you do have offers you could be
              redeeming!
            </Text>
          )}
          {otherOffers?.length && (
            <Stack spacing={5} flexShrink={0}>
              <Stack flexShrink={0}>
                {otherOffers.map(offer => (
                  <AvailableOffer
                    offer={offer}
                    redeemable={false}
                    isLoading={isFetching}
                  />
                ))}
              </Stack>
            </Stack>
          )}
          <Button
            variantColor="pink"
            isLoading={isFetching}
            onClick={() => {
              onClose()
            }}
            flexShrink={0}
          >
            Close
          </Button>
        </Stack>
      </Modal>
    </>
  )
}

export default ModalApplyOffer
