import type { MyOffersSingleOfferResponse } from '@thirsty-camel/hump-club/src/modules/offer/offer.object'
import { AddressObject } from '@thirstycamel/ui'
import type { CartEntity } from '@ts/core/src/modules/cart/cart.entity'
import type CoreProductEntity from '@ts/core/src/modules/coreproduct/coreproduct.entity'
import { Action, action, ActionOn, actionOn, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy'
import Router from 'next/router'
import { destroyCookie, setCookie } from 'nookies'
import { StoreModel } from '.'
import { backend } from '../../utils/backend'
import { eventGA4, eventUA } from '../../utils/gtag'

type CartPayload = Promise<CartEntity>

export interface CartModel {
  cartId?: string
  isFetching: boolean
  cart?: CartEntity
  isPopoutOpen: boolean
  hasOffers: boolean
  openPopout: Action<CartModel>
  hidePopout: Action<CartModel>
  togglePopout: Action<CartModel>
  createCart: Thunk<CartModel, void, void, StoreModel, CartPayload>
  changeStore: Thunk<CartModel, string, void, StoreModel, CartPayload>
  applyOffer: Thunk<CartModel, number>
  removeOffer: Thunk<CartModel, number>
  fetchCart: Thunk<CartModel, string | void, CartEntity, StoreModel, CartPayload>
  addProductToCart: Thunk<CartModel, { product: CoreProductEntity; qty: number }, void, StoreModel>
  changeCartItemQty: Thunk<CartModel, { cartItem: string; qty: number }>
  removeItemsFromCart: Thunk<CartModel, { cartItems: string[] }>
  checkOffers: Thunk<CartModel, { phone: string }, void, StoreModel>
  clearCartId: Action<CartModel>
  setStatus: Action<
    CartModel,
    {
      cartId?: string
      isFetching?: boolean
      cart?: CartEntity
      hasOffers?: boolean
    }
  >
  onChangeStore: ActionOn<CartModel, StoreModel>
  onUpdateCart: ThunkOn<CartModel, StoreModel>
  setDeliveryOption: Thunk<CartModel, { address: AddressObject; option: string }>
  removeDeliveryOption: Thunk<CartModel>
}

const cart: CartModel = {
  cart: null,
  cartId: null,
  isFetching: false,
  isPopoutOpen: false,
  hasOffers: false,
  openPopout: action(state => {
    state.isPopoutOpen = true
  }),
  hidePopout: action(state => {
    state.isPopoutOpen = false
  }),
  togglePopout: action(state => {
    state.isPopoutOpen = !state.isPopoutOpen
  }),
  setStatus: action((state, cartState) => {
    Object.keys(cartState).forEach(k => (state[k] = cartState[k]))
  }),
  clearCartId: action(state => {
    state.cart = null
    state.cartId = null
    destroyCookie(null, 'cart_id', { path: '/' })
  }),
  fetchCart: thunk(async (actions, cId, { getState, getStoreState }) => {
    const storeState = getStoreState()

    const cartId = cId || getState()?.cartId

    if (!cartId || cartId === 'null') return

    actions.setStatus({ isFetching: true })

    try {
      const cart = await backend<CartEntity>('cart', {
        params: {
          cart: cartId,
          humpUserId: storeState?.auth?.user?.id,
          humpUserPhone: storeState?.auth?.user?.mobilePhone,
        },
      })

      if (cart) {
        actions.setStatus({ isFetching: false, cartId: cart.id, cart })
        return cart
      }
    } catch (e) {
      actions.setStatus({ isFetching: false })
    }

    return null
  }),

  addProductToCart: thunk(
    async (actions, { product, qty }, { getState, getStoreActions, getStoreState }) => {
      const state = getStoreState()

      const showModal = getStoreActions().modal.showModal
      actions.setStatus({ isFetching: true })
      let { cartId } = getState()

      if (!cartId) {
        let cart = await actions.createCart()

        cartId = cart.id
      }
      try {
        const { cart } = await backend<{ cart: { id: string } }>('cart/items', {
          method: 'POST',
          data: { product: product.id, qty, cart: cartId, store: state.store.selected },
        })

        /* Backend has updated cart id */
        if (cart.id !== cartId) {
          actions.setStatus({ cartId: cart.id })
        }

        const isMobile = window.matchMedia('(max-width: 30rem)').matches

        /* If we're on mobile, take the user to the cart page.
         * Otherwise, open the cart popout. */
        if (isMobile) {
          Router.push({ pathname: '/cart' }).then(() => window.scrollTo(0, 0))
        } else {
          actions.openPopout()
        }

        const productPrice = (product.specialPricing?.price ?? product.pricing?.price ?? 0) / 100

        /* Send to Google Analytics */
        eventUA('add_to_cart', {
          event_category: 'ecommerce',
          event_label: 'Add to cart',
          currency: 'AUD',
          value: productPrice * qty,
          items: [
            {
              id: product.id,
              name: product.productGroup?.name,
              brand: product.productGroup?.brand?.name,
              content_category: product.productGroup?.categories?.map(cat => cat.name).join('/'),
              price: productPrice,
              quantity: qty,
              variant: product.factorDescription,
            },
          ],
        })
        eventGA4('add_to_cart', {
          currency: 'AUD',
          value: productPrice * qty,
          items: [
            {
              item_id: product.id,
              item_name: product.productGroup?.name,
              item_brand: product.productGroup?.brand?.name,
              item_category: product.productGroup?.categories?.map(cat => cat.name).join('/'),
              price: productPrice,
              quantity: qty,
              item_variant: product.factorDescription,
            },
          ],
        })

        /* Send to Facebook Pixel */
        if (typeof fbq !== 'undefined') {
          fbq?.('track', 'AddToCart', {
            content_ids: [product.id],
            content_type: 'product',
            value: productPrice * qty,
          })
        }
      } catch (e) {
        throw e
      } finally {
        const cart = await actions.fetchCart()

        /* If we are over the max order */
        if (cart?.overMaxOrder) {
          showModal({ name: 'OVER_MAX_ORDER' })
        }
      }
    },
  ),
  createCart: thunk(async (actions, _, { getStoreState }) => {
    const state = getStoreState()

    if (!state.cart.cartId) {
      actions.setStatus({ isFetching: true })

      const cartOptions = {
        store: state.store.selected,
        humpUserId: state.auth.user?.id,
        humpUserPhone: state.auth.user?.mobilePhone,
      }

      let { id: cartId } = await backend<CartEntity>('cart', {
        method: 'POST',
        data: cartOptions,
      })

      actions.setStatus({ cartId, isFetching: false })
    }

    const cart = await actions.fetchCart(state.cart.cartId)

    return cart
  }),

  changeStore: thunk(async (actions, storeId, { getStoreState }) => {
    const state = getStoreState()

    if (state?.cart?.cartId) {
      let { id: cartId } = await backend<CartEntity>(`cart/${state.cart.cartId}/store`, {
        method: 'PATCH',
        data: {
          storeId,
        },
      })

      actions.setStatus({ cartId, isFetching: false })
    }

    const cart = await actions.fetchCart(state.cart.cartId)

    return cart
  }),

  changeCartItemQty: thunk(async (actions, payload, { getState }) => {
    actions.setStatus({ isFetching: true })
    const state = getState()

    await backend('cart/items', {
      data: {
        cart: state.cartId,
        cartItem: payload.cartItem,
        qty: payload.qty,
      },
      method: 'PUT',
    })

    await actions.fetchCart()
  }),

  removeItemsFromCart: thunk(async (actions, payload, { getState }) => {
    actions.setStatus({ isFetching: true })
    const state = getState()

    await backend(`cart/${state.cartId}/items`, {
      data: {
        cartItems: payload.cartItems,
      },
      method: 'DELETE',
    })

    await actions.fetchCart()
  }),

  checkOffers: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const state = getState()
    const showModal = getStoreActions().modal.showModal
    // actions.setStatus({ isFetching: true, hasOffers: false })
    actions.setStatus({ isFetching: true })
    const {
      validOffers,
      otherOffers,
    }: {
      validOffers: MyOffersSingleOfferResponse[]
      otherOffers: MyOffersSingleOfferResponse[]
    } = await backend('cart/check-offers', {
      data: {
        phone: payload.phone,
        cart: state.cartId,
        validateOnly: true,
      },
      method: 'POST',
    })

    if (validOffers.length || otherOffers.length) {
      actions.setStatus({ hasOffers: true })
      showModal({ name: 'APPLY_OFFER', props: { offers: validOffers, otherOffers } })
    }

    actions.fetchCart()
  }),
  applyOffer: thunk(async (actions, payload, { getState }) => {
    actions.setStatus({ isFetching: true })

    try {
      await backend('cart/apply-offer', {
        data: {
          offer: payload,
          cart: getState().cartId,
        },
        method: 'POST',
      })
    } catch (e) {
      actions.setStatus({ isFetching: false })
      throw e
    }

    actions.fetchCart()
  }),
  removeOffer: thunk(async (actions, payload, { getState }) => {
    actions.setStatus({ isFetching: true })

    try {
      await backend('cart/remove-offer', {
        data: {
          offer: payload,
          cart: getState().cartId,
        },
        method: 'POST',
      })
    } catch (e) {
      actions.setStatus({ isFetching: false })
      throw e
    }

    actions.fetchCart()
  }),
  onChangeStore: actionOn(
    (actions, storeActions) => storeActions.store.changeStore,
    state => {
      // state.cart = state.cartId = null
      // destroyCookie(null, 'cart_id', { path: '/' })
    },
  ),
  onUpdateCart: thunkOn(
    store => store.setStatus,
    async (_, { payload }) => {
      if ('cartId' in payload) {
        // hydrated in _app.tsx
        setCookie(null, 'cart_id', payload.cartId, { path: '/' })
      }
    },
  ),
  setDeliveryOption: thunk(async (actions, { address, option }, { getState }) => {
    let { cartId } = getState()

    if (!cartId) {
      let cart = await actions.createCart()

      cartId = cart.id
    }

    try {
      await backend<CartEntity>('delivery/delivery-option', {
        method: 'POST',
        params: { address, option, cart: cartId },
      })

      actions.fetchCart()
    } catch (e) {
      throw e
    }
  }),

  removeDeliveryOption: thunk(async (actions, _, { getState }) => {
    let { cartId } = getState()

    if (!cartId) {
      let cart = await actions.createCart()

      cartId = cart.id
    }

    try {
      await backend<CartEntity>('delivery/delivery-option', {
        method: 'DELETE',
        params: { cart: cartId },
      })

      actions.fetchCart()
    } catch (e) {
      throw e
    }
  }),
}

export default cart
